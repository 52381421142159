<template>
  <CreateCustomerAppComp />
</template>


<script>
export default {
  name: "CreateApplication",
  components: {
    CreateCustomerAppComp: () => import("../../../src/components/customer_applications/CreateCustomerAppComp.vue"),
  },
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Customer Application", path:"/customer-applications", isActive: false}, {text:"Create", path:"", isActive: true}] 
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>